import React from 'react';

const OptOutSuccess: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Opt-Out Successful</h1>
        <p className="text-gray-700 mb-6">
          You have successfully opted out of receiving notifications.
        </p>
        <a
          href="/"
          className="inline-block px-6 py-3 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default OptOutSuccess;
