import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-5xl font-bold text-red-600 mb-4">Error 404</h1>
        <p className="text-lg text-gray-700 mb-6">
          Oops! The page you are looking for could not be found or the token is invalid/expired.
        </p>
        <a
          href="/"
          className="inline-block px-6 py-3 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;
