import styles from '../styles/Header.module.css';

const Header: React.FC = () => {
  return (
    <header>
      <div className={`${styles.container}`}>
        <img src="logo_512.png" alt="EyeKlass Logo" className={`${styles.logo}`} />
        <img src="logo_mobile.png" className={`${styles.mobileLogo}`} alt="EyeKlass Logo" width={125} />
      </div>
    </header>
  );
};

export default Header;
