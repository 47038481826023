import React, { useState } from 'react';
import styles from '../styles/SubscriptionForm.module.css';
import client from '../lib/apolloClient';
import { gql, useMutation } from '@apollo/client';

const CREATE_USER = gql`
  mutation CreateUser($firstName: String!, $lastName: String, $email: String!) {
     createUser(firstName: $firstName, lastName: $lastName, email: $email) {
      status
      message
    }
  }
`;

const SubscriptionForm: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<{ status: "success" | "error", content: string } | null>(null)

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createUser({
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
    }).then((res) => {
      if (res.data.createUser.status) {
        setMessage({ status: "success", content: res.data.createUser.message })
      } else {
        setMessage({ status: "error", content: res.data.createUser.message })
      }
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    })
    // Optionally reset the form
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  return (
    <section className={`${styles.backgroundSection}`}>
      <div className={`${message ? "block" : "hidden"} flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 fixed right-5 top-5`} role="alert">
        {message?.status === "success" ?
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span className="sr-only">Check icon</span>
          </div>
          :
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
            </svg>
            <span className="sr-only">Error icon</span>
          </div>}
        <div className="ms-3 text-sm font-normal">{message?.content}</div>
        <button type="button" onClick={() => { setMessage(null) }} className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
          <span className="sr-only">Close</span>
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
      <div className={`${styles.container}`}>
        <h2 className={`${styles.title}`}>Stay Updated and Get Exclusive Early Access!</h2>
        <form className={`${styles.form}`} onSubmit={onSubmit}>
          <div className={`${styles.row}`}>
            <input
              type="fname"
              required
              placeholder="First Name"
              className={`${styles.input}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <input
              type="lname"
              placeholder="Last Name"
              className={`${styles.input}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className={`${styles.row}`}>
            <input
              type="email"
              required
              placeholder="Email Address"
              className={`${styles.input}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className={`${styles.button}`}>
              Notify Me
            </button>
          </div>
        </form>
        <p className={`${styles.description}`}>
          Sign up now and get a FREE one-month subscription to our Premium Services at launch.
        </p>
      </div>
    </section >
  );
};

export default SubscriptionForm;
